import { useQuery } from "@tanstack/react-query";

import { PublicInformation } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";

import NavList from "./NavList";

import classes from "./Footer.module.css";
import { Element } from "react-scroll";

const AuctionsNavList = [{ name: "Physical Auction", to: "/physical-bidding" }];
const ConnectNavList = [{ name: "Contact Us", to: "/contact-us" }];

const mapUrl =
  "https://www.google.com/maps/search/Plot+No.+19%2F4+%26+27,+Indiqube+Alpha+Building,+1st+Floor,+B4-020+Kadubeesanahalli,+Bangalore+-+560103./@12.9393506,77.6955446,21z?entry=ttu";

const Footer = () => {
  const {
    isLoading,
    isError,
    data: informations,
    error,
  } = useQuery([PublicInformation], async () => {
    const response = await fetch(`${API_BASE_URL}information/get/all`);

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  });

  const date = new Date();
  const year = date.getFullYear();

  return (
    <Element name="contact_us">
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <img className={classes.footerImage} src="/img/footer-image.png" />
          {/* <NavList
          title="Overview"
          isInformation={true}
          links={informations ? informations.data : []}
        />
        <NavList title="Auctions" links={AuctionsNavList} />
        <NavList title="Connect with Us" links={ConnectNavList} /> */}
          <img className={classes.footerIcon} src="/img/footer-logo.svg" />
          <br />
          <div className={classes.footerMobile}>
            <a
              href="tel:8904889032
"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/img/footer-contact.svg"
                style={{ marginRight: "8px" }}
              />
              <p>+91 8904889032</p>
            </a>
          </div>
          <br />
          <div className={classes.footerMail}>
            <a style={{ display: "flex" }} href="mailto:contact@vehiclemall.in">
              <img src="/img/footer-mail.svg" style={{ marginRight: "8px" }} />
              <p>contact@vehiclemall.in</p>
            </a>
          </div>
          <br />
          <div
            className={classes.footerLocation}
            onClick={() => window.open(mapUrl, "_blank")}
          >
            <img src="/img/footer-location.svg" />
            <p>Corporate Office</p>
          </div>
          <br />
          <p
            className={classes.locationDetails}
            onClick={() => window.open(mapUrl, "_blank")}
          >
            No.663,1st floor, ombr layout,
            <br /> 5th main, banaswadi, Bangalore -560043
          </p>
          <br />

          <div className={classes.footerBottom}>
            <p>Copyright vehiclemall © {year} v1.1.9. All rights reserved </p>
            {/* <div style={{ display: "flex" }}>
              <p>Developed By </p>&nbsp;
              <span
                onClick={() => window.open("https://riolabz.com/", "_blank")}
              >
                {" "}
                Riolabz
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Footer;
